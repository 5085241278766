import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currAddress: null,
  currAddressAction: null,
  currUser: null,
  currOrder: null,
  socialMedia: false,
};

export const userSlice = createSlice({
  name: "user",

  initialState,

  reducers: {
    setSocialMedia(state, action) {
      state.socialMedia = action.payload;
    },
    setCurrentAddress(state, action) {
      state.currAddress = action.payload;
    },
    setCurrentAddressAction(state, action) {
      state.currAddressAction = action.payload;
    },
    setCurrentUser(state, action) {
      state.currUser = action.payload;
    },
    setCurrentOrder(state, action) {
      state.currOrder = action.payload;
    },
  },
});

export const {
  setCurrentUser,
  setCurrentAddress,
  setCurrentAddressAction,
  setCurrentOrder,
  setSocialMedia,
} = userSlice.actions;

export default userSlice.reducer;
