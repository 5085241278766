import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currCart: {},
  cartItems: [],
  checkout: {},
  isEditCart: false,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setIsEditCart: (state, action) => {
      state.isEditCart = action.payload;
    },
    setCurrCart: (state, action) => {
      state.currCart = action.payload;
    },
    setCartItems: (state, action) => {
      state.cartItems = action.payload;
    },
    setCheckout: (state, action) => {
      state.checkout = action.payload;
    },
    deleteCartItem: (state, action) => {
      state.cartItems = state.cartItems.filter((item) => item.id !== action.payload.id);
    },
    clearCart: (state) => {
      state.currCart = {};
      state.cartItems = [];
      state.checkout = {};
    },
    updateCartShipmentsArtwork: (state, action) => {
      const { artworkShipments, cartItemId } = action.payload;
      console.log("artworkShipments", artworkShipments);

      if (state.currCart.id === cartItemId) {
        state.currCart = {};
      }
      state.cartItems = state.cartItems.map((item) => {
        if (item.id === cartItemId) {
          return {
            ...item,
            shipping: {
              ...item.shipping,
              shippments: item.shipping.shipments.map((el, index) => {
                return { ...el, sets: artworkShipments[index] };
              }),
            },
          };
        }
        return item;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrCart,
  setCartItems,
  setCheckout,
  deleteCartItem,
  clearCart,
  updateCartShipmentsArtwork,
  setIsEditCart,
} = cartSlice.actions;

export default cartSlice.reducer;
