import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  groupCategories: [],
  products: [],
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setGroupCategories(state, action) {
      state.groupCategories = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCategories, setGroupCategories, setProducts } = menuSlice.actions;

export default menuSlice.reducer;
