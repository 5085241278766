import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSubNav: false,
  isSwipeableDrawer: false,
  isDrawer: false,
  editorRef: null,
  isRotate: false,
  isUserAuthenticated: false,
  isAddressForm: false,
  isTrackOrder: false,
  redirectRoute: null,
  paymentMethodType: "credit-card",
  isBugReport: false,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleBugReport(state, action) {
      state.isBugReport = !state.isBugReport;
    },
    setPaymentMethodType(state, action) {
      state.paymentMethodType = action.payload;
    },
    setRedirectRoute(state, action) {
      state.redirectRoute = action.payload;
    },
    setIsTrackOrder(state, action) {
      state.isTrackOrder = action.payload;
    },
    setIsAddressForm(state, action) {
      state.isAddressForm = action.payload;
    },
    setIsUserAuthenticated(state, action) {
      state.isUserAuthenticated = action.payload;
    },
    setIsSubNav(state, action) {
      state.isSubNav = action.payload;
    },
    setEditorRef(state, action) {
      console.log(action.payload);
      state.editorRef = action.payload;
    },
    setIsSwipeableDrawer(state, action) {
      state.isSwipeableDrawer = action.payload;
    },
    toggleIsRotate(state, action) {
      state.isRotate = action.payload;
    },
    setIsDrawer(state, action) {
      state.isDrawer = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleBugReport,
  setIsSubNav,
  setIsAddressForm,
  setIsUserAuthenticated,
  setIsDrawer,
  setEditorRef,
  setIsSwipeableDrawer,
  toggleIsRotate,
  setIsTrackOrder,
  setRedirectRoute,
  setPaymentMethodType,
} = layoutSlice.actions;

export default layoutSlice.reducer;
