export const themeOptions = {
  direction: "ltr",

  palette: {
    mode: "light",
    primary: {
      main: "#249B47",
      light: "#34c75d",
      dark: "#097528",
    },
    secondary: {
      main: "#f79722",
      light: "#f79722",
      dark: "#ffb972",
    },
  },
};
