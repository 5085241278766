// src/components/CookieConsent.js
import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // const consent = cookies.get("cookieConsent");
    // if (!consent) {
    //   setIsVisible(true);
    // }
    const consent = localStorage.getItem("cookieConsent");
    console.log("consent", consent);
    if (consent === null) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", true);
    // cookies.set("cookieConsent", "true", { path: "/", maxAge: 365 * 24 * 60 * 60 });
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  const handleReject = () => {
    localStorage.setItem("cookieConsent", false);
    // cookies.set("cookieConsent", "false", { path: "/", maxAge: 365 * 24 * 60 * 60 });
    setIsVisible(false);
  };

  return (
    <div style={styles.container}>
      <p>
        We use cookies to improve your experience. By using our site, you agree to our use of
        cookies.
      </p>
      <div>
        <button onClick={handleAccept} style={styles.button}>
          Accept
        </button>
        <button onClick={handleReject} style={styles.button}>
          Reject All
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: "0",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "white",
    textAlign: "center",
    padding: "10px",
    zIndex: "1000",
    minHeight: "20vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  button: {
    marginLeft: "10px",
    padding: "5px 10px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    cursor: "pointer",
  },
};

export default CookieConsent;
