import { combineReducers, configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./Slices/Layout/Layout";
import userReducer from "./Slices/User/User";
import cartReducer from "./Slices/Cart/Cart";
import menuReducer from "./Slices/Menu/Menu";
import settingsReducer from "./Slices/Settings/Settings";
import artworkReducer from "./Slices/Artwork/Artwork";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  // whitelist: ['cart'],
  whitelist: [""],
};

const layoutPersistConfig = {
  key: "layout",
  storage: storage,
  // whitelist: ['cart'],
  whitelist: ["redirectRoute", "paymentMethodType"],
};

const userPersistConfig = {
  key: "user",
  storage: storage,
  // blacklist: ["socialMedia"],
};

const cartPersistConfig = {
  key: "cart",
  storage: storageSession,
};

const menuPersistConfig = {
  key: "menu",
  storage: storage,
};

const settingsPersistConfig = {
  key: "settings",
  storage: storage,
};

const artworkPersistConfig = {
  key: "artwork",
  storage: storage,
};

const reducers = combineReducers({
  layout: persistReducer(layoutPersistConfig, layoutReducer),
  user: persistReducer(userPersistConfig, userReducer),
  cart: persistReducer(cartPersistConfig, cartReducer),
  menu: persistReducer(menuPersistConfig, menuReducer),
  settings: persistReducer(settingsPersistConfig, settingsReducer),
  artwork: persistReducer(artworkPersistConfig, artworkReducer),
});

export const store = configureStore({
  reducer: persistReducer(rootPersistConfig, reducers),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
