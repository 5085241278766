import "./Ribbon.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import React, { Suspense, useEffect, useState } from "react";
import axios from "axios";
import { HashLoader } from "react-spinners";
import { Box } from "@mui/material";
import CookieConsent from "./components/CookieConsent/CookieConsent";
import Cookies from "universal-cookie";
import NewsletterForm from "./components/NewsLetterForm/NewsLetterForm";
const Home = React.lazy(() => import("./pages/Home/Home"));
const Category = React.lazy(() => import("./pages/Category/Category"));
const Product = React.lazy(() => import("./pages/Product/Product"));
const Cart = React.lazy(() => import("./pages/Cart/Cart"));
const Checkout = React.lazy(() => import("./pages/Checkout/Checkout"));
const LoginPage = React.lazy(() => import("./pages/Auth/LoginPage"));
const RegisterPage = React.lazy(() => import("./pages/Auth/RegisterPage"));
const CustomerHome = React.lazy(() => import("./pages/Customer/Home/Home"));
const Protected = React.lazy(() => import("./components/Protected/Protected"));
const VerifyEmail = React.lazy(() => import("./pages/Auth/VerifyEmail"));
const MainCategory = React.lazy(() => import("./pages/MainCategory/MainCategory"));
const Faqs = React.lazy(() => import("./pages/FAQs/Faqs"));
const ReturnPolicy = React.lazy(() => import("./pages/ReturnPolicy/ReturnPolicy"));
const ShippingPolicy = React.lazy(() => import("./pages/ShippingPolicy/ShippingPolicy"));
const RewardRules = React.lazy(() => import("./pages/RewardRules/RewardRules"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const RequestQuote = React.lazy(() => import("./pages/RequestQuote/RequestQuote"));
const ContactUs = React.lazy(() => import("./pages/ContactUs/ContactUs"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword/ResetPassword"));
const WithScrollRestoration = React.lazy(() =>
  import("./layout/WithScrollRestoration/WithScrollRestoration")
);
const EventVirginChurch = React.lazy(() => import("./pages/EventVirginChurch/EventVirginChurch"));

const Favorites = React.lazy(() => import("./pages/Customer/Favorites/Favorites"));

// import Home from "./pages/Home/Home";
// import Category from "./pages/Category/Category";
// import Product from "./pages/Product/Product";
// import Cart from "./pages/Cart/Cart";
// import Checkout from "./pages/Checkout/Checkout";
// import LoginPage from "./pages/Auth/LoginPage";
// import RegisterPage from "./pages/Auth/RegisterPage";
// import CustomerHome from "./pages/Customer/Home/Home";
// import Protected from "./components/Protected/Protected";
// import VerifyEmail from "./pages/Auth/VerifyEmail";
// import MainCategory from "./pages/MainCategory/MainCategory";
// import Faqs from "./pages/FAQs/Faqs";
// import ReturnPolicy from "./pages/ReturnPolicy/ReturnPolicy";
// import ShippingPolicy from "./pages/ShippingPolicy/ShippingPolicy";
// import RewardRules from "./pages/RewardRules/RewardRules";
// import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
// import RequestQuote from "./pages/RequestQuote/RequestQuote";
// import ContactUs from "./pages/ContactUs/ContactUs";
// import ResetPassword from "./pages/ResetPassword/ResetPassword";
// import WithScrollRestoration from "./layout/WithScrollRestoration/WithScrollRestoration";
// import Favorites from "./pages/Customer/Favorites/Favorites";

axios.defaults.withCredentials = true;

const router = createBrowserRouter([
  {
    path: "/",
    element: <WithScrollRestoration />,
    children: [
      // {
      //   index: true,
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <Home />
      //     </Suspense>
      //   ),
      // },
      // {
      //   path: "category/:categoryId",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <Category />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "main-category/:categoryId",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <MainCategory />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "category/:categoryId/product/:productId",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <Product />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "cart",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <Cart />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "checkout",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <Checkout />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "faqs",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <Faqs />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "return-policy",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <ReturnPolicy />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "shipping-policy",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <ShippingPolicy />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "reward-rules",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <RewardRules />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "request-quote",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <RequestQuote />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "contact-us",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <ContactUs />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "privacy-policy",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <PrivacyPolicy />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "auth/:userId/verify/:tokenId",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <VerifyEmail />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "login",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <LoginPage />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "register",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <RegisterPage />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "auth/reset-password/:tokenId",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <ResetPassword />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "favorites",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <Protected Component={Favorites} />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      // {
      //   path: "home",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
      //           <HashLoader color="#249B47" size={100} />
      //         </Box>
      //       }
      //     >
      //       <Protected Component={CustomerHome} />
      //     </Suspense>
      //   ),
      //   handle: { scrollMode: "pathname" },
      // },
      {
        path: "vmoc",
        element: (
          <Suspense
            fallback={
              <Box className="bg-[#292d32] h-screen w-screen flex justify-center items-center">
                <HashLoader color="#249B47" size={100} />
              </Box>
            }
          >
            <EventVirginChurch />
          </Suspense>
        ),
        handle: { scrollMode: "pathname" },
      },
    ],
  },
]);

// const router = createBrowserRouter([
//   {
//     path: "/",
//     // index: true,
//     element: <WithScrollRestoration />,
//     children: [
//       {
//         index: true,
//         element: <Home />,
//       },
//       {
//         path: "category/:categoryId",
//         element: <Category />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "main-category/:categoryId",
//         element: <MainCategory />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "category/:categoryId/product/:productId",
//         element: <Product />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "cart",
//         element: <Cart />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "checkout",
//         element: <Checkout />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "faqs",
//         element: <Faqs />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "return-policy",
//         element: <ReturnPolicy />,
//         handle: { scrollMode: "pathname" },
//       },

//       {
//         path: "shipping-policy",
//         element: <ShippingPolicy />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "reward-rules",
//         element: <RewardRules />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "request-quote",
//         element: <RequestQuote />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "contact-us",
//         element: <ContactUs />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "privacy-policy",
//         element: <PrivacyPolicy />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "auth/:userId/verify/:tokenId",
//         element: <VerifyEmail />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "login",
//         element: <LoginPage />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "register",
//         element: <RegisterPage />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "auth/reset-password/:tokenId",
//         element: <ResetPassword />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "favorites",
//         element: <Protected Component={Favorites} />,
//         handle: { scrollMode: "pathname" },
//       },
//       {
//         path: "home",
//         element: <Protected Component={CustomerHome} />,
//         handle: { scrollMode: "pathname" },
//       },
//     ],
//   },
// ]);
if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

function App() {
  useEffect(() => {
    if (location.pathname !== "/") {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    console.log(
      "localStorage.getItem(cookieConsent)",
      typeof localStorage.getItem("cookieConsent")
    );

    // if (localStorage.getItem("cookieConsent") === "false") {
    if (localStorage.getItem("jwt-token")) {
      console.log("setting");
      axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
        "jwt-token"
      )}`;
    }
    // }
  }, []);

  return (
    <>
      {/* <NewsletterForm /> */}
      <CookieConsent />
      <Suspense>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
}

export default App;
