import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isArtworkModule: false,
  artworkShipments: [],
  currArtwork: null,
  currSize: null,
  currFaces: [],
  type: null,
  isMultipage: false,
};

export const artworkSlice = createSlice({
  name: "artwork",
  initialState,
  reducers: {
    setIsMultipage(state, action) {
      state.isMultipage = action.payload;
    },
    setIsArtworkModule(state, action) {
      state.isArtworkModule = action.payload;
    },

    setArtworkShipments(state, action) {
      state.artworkShipments = action.payload;
    },

    updateArtworkShipments(state, action) {
      const { shipmentIndex, setIndex, artworkIndex, artwork } = action.payload;
      state.artworkShipments[shipmentIndex][setIndex].artwork[artworkIndex] = artwork;
    },
    setCurrArtwork(state, action) {
      state.currArtwork = action.payload;
    },
    setCurrSize(state, action) {
      state.currSize = action.payload;
    },
    deleteArtwork(state, action) {
      const { shipmentIndex, setIndex, artworkIndex } = action.payload;
      state.artworkShipments[shipmentIndex][setIndex].artwork[artworkIndex] = null;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setCurrentFaces(state, action) {
      state.currFaces = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsArtworkModule,
  setArtworkShipments,
  updateArtworkShipments,
  setCurrArtwork,
  setCurrSize,
  deleteArtwork,
  setType,
  setCurrentFaces,
  setIsMultipage,
} = artworkSlice.actions;

export default artworkSlice.reducer;
